<template>
  <div>
    <h4 v-if="has_title">{{title}}</h4>
    <select name="select" v-model="selected_order">
      <option value="value1">Value 1</option>
      <option value="value2">Value 2</option>
      <option value="value3">Value 3</option>
    </select>
    <div v-if="is_tolgeo_mex" id="btn_analytics"></div>
    <div>
      <a class="btn btn-link text-uppercase text-small" v-on:click="save_search" title="$t('guardar_busqueda')" v-if="!is_cgae">
        {{ t('guardar_busqueda') }}
      </a>
    </div>
    <documents-item-vue v-for="document in documents" v-bind:key="document.id" v-bind:search_type='search_type' @save:document="save_document"></documents-item-vue>
    <user-subscription-modal-login-vue ref="user_subscription_login_modal" @login:success="handleLoginSuccess"/>
  </div>
</template>

<script>
import token_formatter from 'common/token_formatter.js'
import mixins from 'common/mixins.js'
import { mapGetters } from "vuex";

export default {
  props: ['title', 'documents', 'page', 'rows', 'num_found', 'order', 'loading_documents', 'search_tokens', 'token_id', 'search_type'],
  data() {
    return {
      selected_order: this.order,
      loginSuccessAction: {}
    }
  },
  components: {
    'documents-item-vue': () => import(/* webpackChunkName: "[request]" */ "components/documents/Item"),
    "user-subscription-modal-login-vue": () =>import( /* webpackChunkName: "[request]" */ "components/user_subscription/ModalLogin"),
  },
  computed: {
    cleaned_tokens() {
      return token_formatter.format_and_split_tokens(this.search_tokens)
    },
    has_title() {
      return this.title !== ''
    },
    ...mapGetters(["has_user_subscription"])
  },
  methods: {
    do_paginate() {
      this.$emit('scroll_elements');
    },
    print_result_list(){
      this.$global_utils.print_element('#results_list_container', "result_list")
    },
    save_search() {
      if(this.has_user_subscription){
        this.http_get({
          url: this.url_for_user_subscriptions_search_new({"search[token_id]": this.token_id}),
          format: "JS"
        })
      } else{
        this.loginSuccessAction = {name: 'save_search', attr: {}}
        this.showLoginForm()
      }
    },
    save_document(params){
      if(this.has_user_subscription){
        this.http_get({
          url: this.url_for_user_subscriptions_document_new(params),
          format: "JS"
        })
      }else {
        this.loginSuccessAction = {name: 'save_document', 'attr': params}
        this.showLoginForm()
      }
    },
    showLoginForm() {
      this.$refs.user_subscription_login_modal.show()
    },
    handleLoginSuccess(){
      if(this.loginSuccessAction.name == 'save_document'){
        this.save_document(this.loginSuccessAction.attr)
      }else if (this.loginSuccessAction.name ==  'save_search'){
        this.save_search()
      }
    }
  },
  watch:{
    selected_order(new_value,old_value){
      this.$emit('update:order', new_value);
    }
  },
  mixins: [mixins.requests, mixins.translate]
}
</script>
