<template>
  <div>
    <template >
      <documents-header-vue
        :clazz="clazz"
        :header_fields="header_fields"
        :tituloarticulo="descriptores.tituloarticulo"
        :numero_tol="numero_tol"
      />
      <div class="mt-2" v-if='indice_fields.length > 0'>
        <h5>
          {{ t('components.documents.quick_view.indice') }}
        </h5>
        <div
          v-for='field in indice_fields'
          :class='indice_field_class(field)'
          :key="'indice_field_' + field.id"
        >
          <a :href='indice_field_ref(field)'>{{ field.label }}</a>
        </div>
      </div>
      <div class="mt-2">
        <h3 v-if='emptyBodyFields'>
          {{ t('components.documents.quick_view.empty_document') }}
        </h3>
        <documents-section-vue
          v-for='section in body_fields'
          :key='section.id'
          v-bind='section'
          :cleaned_tokens='cleaned_tokens'
          :show_all='show_all'
          :highlight_background='highlightBgOrShowAll'
        />
      </div>
      <div v-for='file in file_fields' :key='file.id'>
        <a class="pb-4" :href='file.url'>
          <i class="fas fa-download"></i>
          {{ t('components.documents.quick_view.document_file_url') }}
        </a>
      </div>
    </template>
  </div>
</template>

<script>

import mixins from 'common/mixins'
import document_mixin from 'common/mixins/document_mixin'

export default {
  name: "documents-quick-view-vue",
  props: {
    indice_fields: { type: Array, default: () => [] },
    cleaned_tokens: { type: Array, default: () => [] },
    show_all: { type: Boolean, default: false },
    file_fields: { type: Array, default: () => [] },
    numero_tol: { type: String, default: '' },
    highlight_background: { type: Boolean, default: null }
  },
  components: {
    'documents-section-vue': () => import(/* webpackChunkName: "[request]" */ "components/documents/Section"),
    'documents-header-vue': () => import(/* webpackChunkName: "[request]" */ "components/documents/Header")
  },
  mixins: [document_mixin, mixins.translate],
  methods: {
    indice_field_ref: ({ id }) => `#${id}`,
    indice_field_class: ({ depth }) => `depth_${depth}`
  },
  computed: {
    emptyBodyFields() {
      return this.body_fields.length < 1
    },
    highlightBgOrShowAll() {
      return this.highlight_background == null ? this.show_all : this.highlight_background
    }
  }
}
</script>
