<template>
  <div>
  <button id="do_filter" v-show="button_filter_visible" @click="perform_search" />
  <button id="reset_filters_show" v-show="has_filters" @click="show_reset_modal" />
  <div id="reset_filters_modal" v-show="reset_modal_visible">
    <button id="reset_filters_confirm" @click="reset_filters" />
    <button id="reset_filters_cancel" @click="hide_reset_modal" />
  </div>
  <forms-mini-search-box-vue @perform_search="perform_search" :search_tokens.sync="search_tokens" :query_field.sync="query_field" :query_format.sync="query_format" :sub_searches.sync="sub_searches" />
  <div>
    <div id= "radio_button_search" v-if="mustShowRadioButtons">
      <button id='first_radio' disabled="true">
        {{radioLabels.filter}}
      </button>
      <button id='second_radio' @click = "update_search('#{@filter_tree_id}', [])">
          Todo {{radioLabels.parent}}
      </button>
    </div>
  </div>
  <div id="no_results_info" v-if="showNoResultsFound">
    <img id='anagrama_img' src="anagrama.svg">
    <h4 id="no_results_text">
      {{ noResultsFiltersText + noResults }}
    </h4>
    <a id="lo_buscamos"  href="/tol/selobuscamos">se lo buscamos</a>
  </div>
  <span class="search_token" v-for="token in search_tokens" v-bind:key="token">{{token}}</span>
  <span class="sub_search" v-for="sub_search in sub_searches" v-bind:key="sub_search">{{label}}:{{value}}</span>
  <span id="num_found">{{num_found}}</span>
  <div>
      <forms-filters-tree-vue
        v-if="show_filter_tree"
        @update:filter="update_search"
        @send:parameterized_request="fetchParameterizedRequest"
        :has_search_tokens="hasSearchTokens"
      />
      <search-facet-filters-vue v-else :filters="filters" :applied_filters="safe_applied_filters" @update_filter="update_filter" :facets="facets" :class="{panel_collapsed: panel_collapsed}" />
  </div>
  <div class="breadCrumbsOnResults" v-if="forBreadcrumAppliedFilters">
    <filter-breadcrumb-vue />
  </div>
  <search-list-vue @scroll_elements="scroll_elements" :documents="documents" :page="page" :rows="rows" :num_found="num_found" :order.sync="order" :search_type="input_search_type" />
  <span class="facet" v-for="facet in facets" :key="facet.id">{{facet}}</span>
  </div>
</template>

<script>
import Vue from 'vue'
import List from './search/List.vue'
import FacetFilters from './search/FacetFilters.vue'
import MiniSearchBox from './forms/MiniSearchBox.vue'
import FiltersTree from './forms/FiltersTree.vue'
import mixins from './../common/mixins.js'
import search_mixin from 'common/mixins/search_mixin'
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: "results-list-main-vue",
  props: {
    config: { type: Object, default: () => {} },
    index: { type: Number, default: 0 },
    input_documents: { type: Array, default: () => [] },
    input_facets: { type: Array, default: () => [] },
    input_filters: { type: Array, default: () => [] },
    input_group_type: { type: String, default: '' },
    input_num_found: { type: Number, default: 0 },
    input_query_field: { type: String, default: '' },
    input_query_format: { type: String, default: '' },
    input_search_tokens: { type: Array, default: () => [] },
    input_search_type: { type: String, default: '' },
    input_sort: { type: String, default: '' },
    input_sub_searches: { type: Array, default: () => [] },
    radio_button_filter_name: { type: String, default: '' },
    radio_button_parent_name: { type: String, default: '' },
    show_filter_tree: { type: Boolean, default: false },
    token_id: { type: String, default: '' },
  },

  data() {
    return {
      applied_filters: JSON.parse(JSON.stringify(this.input_filters || [])) || [],
      button_filter_visible: false,
      documents: this.input_documents || [],
      documentsLoading: false,
      facets:  this.input_facets || [],
      facetsLoading: false,
      filters: JSON.parse(JSON.stringify(this.input_filters || [])) || [],
      loading_part: 'all',
      num_found: this.input_num_found || 0,
      order: this.input_sort || '',
      page: 0,
      panel_collapsed: (this.config || {}).input_panel_collapsed || false,
      panel_expanded: false,
      query_field: this.input_query_field || 'all',
      query_format: this.input_query_format || 'substring',
      reset_modal_visible: false,
      rows: 25,
      search_tokens: this.input_search_tokens || [],
      sub_searches: this.input_sub_searches || [],
    }
  },
  components: {
    'search-facet-filters-vue': FacetFilters,
    'forms-filters-tree-vue': FiltersTree,
    'forms-mini-search-box-vue': MiniSearchBox,
    'search-list-vue': List
  },
  created() {
    this.checkTolNumberOnSearchToken(this.input_search_tokens)
    if (this.documents.length == 0)
      if (this.index == undefined) {
        this.fetch_data(this.rows)
      } else {
        var final_page = Math.floor(this.index/this.rows)
        this.fetch_data((final_page+1) * this.rows, true)
        this.page = final_page
      }
    else
      this.page++
  },
  methods: {
    scroll_elements() {
      if (this.loading) { return }

      var self = this
      self.loading_part = 'documents'
      this.send_request(function(data) {
        self.documents = self.documents.concat(data.body.result)
        self.page++
      }, this.rows)
    },
    fetch_data(rows, scroll_to_doc=false) {
      var self = this
      self.page = 0
      self.loading_part = 'all'
      this.applied_filters = JSON.parse(JSON.stringify(this.filters))
      this.setFilters(this.applied_filters)
      this.fetchDocuments(scroll_to_doc, rows)
      this.fetchFacets()
    },
    fetchDocuments(scrollToDoc, rows) {
      if (this.documentsLoading) {
        return
      }

      this.documentsLoading = true
      this.http_put({
        url: this.url_for_search_update(this.input_search_type, this.token_id),
        params: {
          search_tokens: this.search_tokens,
          sub_searches: this.sub_searches,
          query_field: this.query_field,
          query_format: this.query_format,
          filters: this.applied_filters,
          group_type: this.input_group_type,
          rows: rows,
          page: this.page,
          sort: this.order,
          config: this.config,
          empty_facets: true,
        },
        success: this.fetchDocumentsCallback.bind(this, scrollToDoc)
      })
    },
    fetchDocumentsCallback(scrollToDoc, data) {
      this.documents = data.body.result
      this.num_found = data.body.total
      this.setResults(data.data)
      this.page++
      this.documentsLoading = false

      if (scrollToDoc) {
        Vue.nextTick(() => {
          const element = document.getElementById(`doc_${this.index}`)
          if (element)
            element.scrollIntoView();
        })
      }
    },
    fetchFacets() {
      this.facetsLoading = true
      this.http_put({
        url: this.url_for_search_update(this.input_search_type, this.token_id),
        params: {
          search_tokens: this.search_tokens,
          sub_searches: this.sub_searches,
          query_field: this.query_field,
          query_format: this.query_format,
          filters: this.applied_filters,
          group_type: this.input_group_type,
          page: this.page,
          sort: this.order,
          config: this.config,
          empty_documents: true,
        },
        success: this.facetsCallback.bind(this)
      })
    },
    facetsCallback(data) {
      this.facets = data.body.facets
      this.facetsLoading = false

    },
    fetchParameterizedRequest(params){
      var self = this
      params.search_tokens =  this.search_tokens
      this.sendParameterizedRequest(function(data) {
        self.setSearchTokensFacets(data.body.facets)
      }, params)
    },
    sendParameterizedRequest(callback, params) {
      this.http_put({
        url: this.url_for_search_update(this.input_search_type, false),
        params: params,
        success: callback
      })
    },
    send_request(callback, rows) {
      if (!this.loading) {
        this.http_put({
          url: this.url_for_search_update(this.input_search_type, this.token_id),
          params: {
            search_tokens: this.search_tokens,
            sub_searches: this.sub_searches,
            query_field: this.query_field,
            query_format: this.query_format,
            filters: this.applied_filters,
            group_type: this.input_group_type,
            rows: rows,
            page: this.page,
            sort: this.order,
            config: this.config
          },
          success: callback
        })
      }
    },
    perform_search() {
      this.button_filter_visible = false
      this.checkTolNumberOnSearchToken(this.search_tokens)
      this.fetch_data(this.rows)
    },
    update_search(filter_name, values){
      this.update_filter(filter_name, values, 'OR')
      this.perform_search()
      this.setFilters(this.filters)
    },
    toggle_collapsed() {
      this.panel_collapsed = !this.panel_collapsed
      if (this.show_filter_tree) {
        this.$refs['filters_tree'].panel_collapsed = this.panel_collapsed
      }
    },
    toggle_expanded() {
      this.panel_expanded = !this.panel_expanded
    },
    show_reset_modal() {
      this.reset_modal_visible = true
    },
    hide_reset_modal() {
      this.reset_modal_visible = false
    },
    update_filter(name, values, operator) {
      var self = this
      var index = this.filters.findIndex(function(element) {return element.name == name})
      if (index == -1) {
        if (values.length > 0)
          this.filters.push(this.new_filter(name, values, operator))
      } else {
        if(values.length > 0) {
          this.filters[index].values = values
          this.filters[index].operator = operator
        } else {
          if (this.filters.length > 1)
            this.filters.splice(index, 1)
          else
            this.filters = []
        }
      }
      if(!this.show_filter_tree)
        this.button_filter_visible = this.calculateButtonVisible()
    },
    calculateButtonVisible(){
      let sortedFilters = this.sortFiltersByName()
      let sortedAppliedFilters = this.sortedAppliedFiltersByName()
      let sortedFiltersNames = sortedFilters.map((el) => el.name)
      let sortedAppliedFiltersNames = sortedAppliedFilters.map((el) => el.name)
      let showButton = false;
      if(this.arraysEqual(sortedFiltersNames, sortedAppliedFiltersNames)){
        for (let i = 0; i < sortedFilters.length; i++) {
          const valuesFilters = sortedFilters[i].values;
          const valuesAppliedFilters = sortedAppliedFilters[i].values

          if (!this.arraysEqual(valuesFilters, valuesAppliedFilters)) {
            showButton = true;
            break;
          }
        }
      } else { showButton = true }
      return showButton
    },
    sortFiltersByName(){
      return this.safe_filters.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    sortedAppliedFiltersByName(){
      return this.safe_applied_filters.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    reset_filters() {
      this.hide_reset_modal()
      this.filters = []
    },
    filterHasValues(values){
      if (values == null) return false
      return values.length > 0
    },
    checkTolNumberOnSearchToken(search) {
      if(search){
        let searchText = search.join(' ')
        if (searchText.length > 0 &&
            this.shouldRedirectToDocumentDetail(searchText)){
          this.loading = true
        }
      }
    },
    shouldRedirectToDocumentDetail(searchText){
      return this.search_redirect_to_document_detail(searchText, this.input_query_format)
    },
    ...mapMutations('search', ['setFilters', 'setResults', 'setSearchTokensFacets'])
  },
  computed: {
    ...mapGetters('search', ['appliedFilters']),
    hasSearchTokens() {
      return this.search_tokens.length > 0
    },
    loading_all() {
      return this.facetsLoading || this.documentsLoading
    },
    loading_documents() {
      return this.loading && this.loading_part == 'documents'
    },
    panel_class() {
      return this.panel_collapsed ? 'collapsed' : this.panel_expanded ? 'expanded': ''
    },
    has_filters() {
      return this.filters.filter(function(element) {return element.hidden != "true"}).length > 0
    },
    get_title() {
      return (this.config || {}).list_title || ''
    },
    safe_filters() {
      return JSON.parse(JSON.stringify(this.filters))
    },
    safe_applied_filters() {
      return JSON.parse(JSON.stringify(this.applied_filters))
    },
    showNoResultsFound() {
      return !this.loading && this.show_filter_tree && this.num_found == 0
    },
    mustShowRadioButtons() {
      return this.radioLabels.filter != '' && this.show_filter_tree
    },
    noResultsFiltersText(){
      if(this.search_tokens.length == 0){
        return '""'
      }
      else if(this.search_tokens.length == 1){
        return `"${this.search_tokens[0]}"`
      }
      else{
        let quoted_search_tokens = this.search_tokens.map((token) => '"'+token+'"' )
        let last_token = quoted_search_tokens.pop()
        return `${quoted_search_tokens.join(', ')} y ${last_token}`
      }
    },
    noResults(){
      return this.radioLabels.filter != '' ? this.radioLabels.filter : this.radioLabels.parent
    },
    radioLabels(){
      let names = { parent: '', filter: '' }
      let indexFilter = this.filters.find((filter) => filter.name == this.radio_button_filter_name)

      names['parent'] = this.radio_button_parent_name
      if(indexFilter && indexFilter.values){
        names['filter'] = this.filterNodeName(indexFilter.values[0])
      }

      return names
    },
    forBreadcrumAppliedFilters() {
      let filters = this.safe_applied_filters.filter((el) => el.values instanceof Array)
      return filters.some((el) => el.values.some((ol) => ol !=null && ol.includes('//')))
    }
  },
  watch:{
    order(new_value, old_value) {
      this.perform_search()
    }
  },
  mixins: [mixins.requests, mixins.filters, mixins.array_utils, search_mixin]
}

</script>
