<template lang="pug">
div.h-100.mt-3.box-list-filter
  div(v-if="show_search_by_input" )
    list-by-input-search-vue(
      :listElements="listElements"
      @perform:search="updateSelectedValue"
      @toggle:hasInput="toggleHasInput"
    )

  div(v-if="!hasInput" id='navigation-tree')
    forms-navigation-tree-vue(
      v-model="selected"
      :nodes="nodes"
      :counts_visible="false"
      :hide_first_level="hide_first_level"
      :filter_name="filter_name"
    )
</template>

<script>
import mixins from 'common/mixins'
import { mapMutations, mapActions, mapState } from 'vuex'
import MultipleAutocompleteTreeSelector from 'components/forms/MultipleAutocompleteTreeSelector'
import { domainBreadcrumb } from "common/classes/breadcrumbs.js";

export default {
  name: 'navigation-search-vue',
  props: {
    show_search_by_input: { type: Boolean, default: false },
    preselectedFilters: { type: Array, default: () => [] },
    search_type: { type: Object, default: () => { } },
    nodes: { type: Array, default: () => [] },
    filter_name: { type: String, default: '' },
    hide_first_level: { type: Boolean, default: false}
  },
  mixins: [mixins.filters, mixins.translate],
  extends: MultipleAutocompleteTreeSelector,
  components: {
    "list-by-input-search-vue": () => import(
      /* webpackChunkName: "[request]" */ "components/forms/ListByInputSearch"
    ),
    "forms-navigation-tree-vue": () => import(
      /* webpackChunkName: "[request]" */ "components/forms/NavigationTree"
    )
  },
  data: function () {
    return {
      selected: '',
      valueInput : '',
      filteredListElements: [],
      hasInput: false,
      listElements: this.parseNodes(this.nodes[0]['children'])
    }
  },
  methods: {
    toggleHasInput(value){
      this.hasInput = value
    },
    updateSelectedValue(id){
      this.selected = id
    },
    minHeightClass() {
      return gon.current_app != "tol" ? "result-list__elements-filters" : "";
    },
    search(id) {
      this.selected = id
      this.set_search_parameters(id)
      this.saveBreadcrumbs()
      this.performSearch()
    },
    set_search_parameters(id){
      this.setSearchType('general')
      this.setFilters([this.new_filter(this.filter_name, [id])])
    },
    saveBreadcrumbs() {
      let service = new domainBreadcrumb(
       {id: this.selected}, gon.current_app, this.filteredListElements, this.savedOption
      )
      this.setBreadcrumbs(service.getBreadcrumbs())
    },
    parseNodes(data){
      if (data === 0) { return [] }

      let elementsToFind = []

      data.forEach(item => {
        elementsToFind.push({ id: item.id, name: item.name });
        if (item.children && item.children.length > 0) {
          elementsToFind = elementsToFind.concat(this.parseNodes(item.children))
        }
      })
      return elementsToFind
    },
    ...mapMutations('search', ['setSearchType','setFilters','setBreadcrumbs']),
    ...mapActions('search', ['performSearch', 'createToken'])
  },
  computed: {
    relatedPreselectedFilter(){
      return this.preselectedFilters.find((e) => e.name == this.filter_name)
    },
    ...mapState('search', ['savedOption'])
  },
  watch:{
    selected(newValue){
      this.search(newValue)
    }
  },
  mounted(){
    if(this.relatedPreselectedFilter){
      this.selected = this.relatedPreselectedFilter.values[0]
    }
  }
};
</script>
