/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Vue from 'vue/dist/vue.esm'
import VueResource from 'vue-resource'
import Print from "vue-print-nb"
import Vuex from "vuex";
import Hotjar from 'vue-hotjar'


import MainSearchBox from 'components/forms/MainSearchBox'
import DateSelector from 'components/forms/DateSelector'
import MultiSelectButton from 'components/forms/MultiSelectButton'
import VoiceTextArea from 'components/forms/VoiceTextArea'
import FilterValueAutocomplete from 'components/forms/FilterValueAutocomplete'
import DirectAccessSelector from 'components/forms/DirectAccessSelector'
import DocumentsItem from 'components/documents/Item'
import GenericInput from 'components/forms/GenericInput'
import PasswordInput from 'components/forms/PasswordInput'
import RequestPasswordRecovery from 'components/user_subscription/RequestPasswordRecovery'
import PasswordChange from 'components/user_subscription/PasswordChange'
import RegisterForm from 'components/user_presubscription/RegisterForm'
import LoginForm from 'components/user_subscription/LoginForm'
import LopdText from 'components/forms/LopdText'
import UserSubscriptionsNoveltySubscription from 'components/user_subscription/NoveltySubscription'
import UserSubscriptionsLateralMenu from 'components/user_subscription/LateralMenu'
import DropOutText from 'components/user_subscription/DropOutText'

import html2canvas from 'html2canvas'
import QuickView from 'components/documents/QuickView'
import SofiaFileAnalyzer from 'components/sofia/FileAnalyzer'
import SofiaHeader from 'components/sofia/Header'
import StaticContent from 'components/StaticContent'

import SofiaBrand from 'components/sofia/Brand'
import Wrapper from '../components/conversa/Wrapper.vue';
import ConversaButton from '../components/conversa/ConversaButton.vue'

import store from 'store/main'

var requireComponent = require.context('components', false, /.*\.vue$/)

requireComponent.keys().forEach(fileName => {
  var componentConfig = requireComponent(fileName)
  var component = (componentConfig.default || componentConfig)
  Vue.component(component.name, component)
})

Vue.use(Vuex)
Vue.use(VueResource)
Vue.use(Print);
Vue.use (Hotjar, {
  id: '5004010',
  isProduction: true
})


let prepend_initializer = controller_name => {
  try {
    require(`initializers/${controller_name}`)
  } catch {
    return false
  }
}

prepend_initializer('global')
prepend_initializer(gon.controller_name)

// Vue object must define: rows, page, num_found and loading
Vue.directive('infinite-scroll', {
  inserted: function (el, binding, vnode) {
    el.onscroll = () => {
      var end_not_reached = vnode.context.rows * vnode.context.page < vnode.context.num_found
      var has_scroll = el.offsetHeight < el.scrollHeight

      if (has_scroll && el.scrollTop >= el.scrollHeight - el.offsetHeight - 1000 && !vnode.context.loading && end_not_reached) {
        binding.value()
      }
    }
  },
  update: function (el, binding, vnode) {
    var end_not_reached = vnode.context.rows * vnode.context.page < vnode.context.num_found
    setTimeout(function() {
      var content_height = 0
      for (let child of el.children) {
        content_height += child.offsetHeight
      }
      if (content_height < el.offsetHeight && !vnode.context.loading && end_not_reached)
        binding.value()
    }, 1000);
  }
})

Vue.directive('empty',
  {
    update: function (el, binding) {
      setTimeout(() => {
        if(el.offsetHeight < 25){
          if(binding.value.show_message){
            $('#empty_message').html(binding.value.text_parent)
            $('#select_all').hide()
          }
          else{
            $('#empty_message').html(binding.value.text)
            $('#select_all').hide()
          }
        }else{
          $('#empty_message').html('')
          $('#select_all').show()
        }
      }, 100)
    }
  }
)

Vue.component('forms-main-search-box-vue', MainSearchBox)
Vue.component('forms-date-selector-vue', DateSelector)
Vue.component('direct-access-selector-vue', DirectAccessSelector)
Vue.component('forms-multi-select-button-vue', MultiSelectButton)
Vue.component('forms-filter-value-autocomplete-vue', FilterValueAutocomplete)
Vue.component('forms-voice-text-area-vue', VoiceTextArea)
Vue.component('documents-item-vue', DocumentsItem)
Vue.component('documents-quick-view-vue', QuickView)
Vue.component(SofiaFileAnalyzer.name, SofiaFileAnalyzer)
Vue.component(Wrapper.name, Wrapper)
Vue.component(ConversaButton.name, ConversaButton)
Vue.component(SofiaHeader.name, SofiaHeader)
Vue.component(StaticContent.name, StaticContent)

Vue.component(RequestPasswordRecovery.name, RequestPasswordRecovery)
Vue.component(PasswordChange.name, PasswordChange)
Vue.component(QuickView.name, QuickView)
Vue.component(GenericInput.name, GenericInput)
Vue.component(PasswordInput.name, PasswordInput)
Vue.component(RegisterForm.name, RegisterForm)
Vue.component(LopdText.name, LopdText)
Vue.component(SofiaBrand.name, SofiaBrand)
Vue.component(LoginForm.name, LoginForm)
Vue.component(UserSubscriptionsNoveltySubscription.name, UserSubscriptionsNoveltySubscription)
Vue.component(UserSubscriptionsLateralMenu.name, UserSubscriptionsLateralMenu)
Vue.component(DropOutText.name, DropOutText)
Vue.component(
  "forms-toggle-filters-vue",
  () => import(/* webpackChunkName: "[request]" */ "components/forms/ToggleFilters")
)


document.addEventListener('DOMContentLoaded', () => {
  document.store = store
  new Vue({
    el: '[data-behaviour="vue"]',
    store: new Vuex.Store(store),
    methods: {
      form_reset(form_identifier) {
        var self = this
        Object.keys(this.$refs).forEach((element) => {
          if (element.includes(`_${form_identifier}_`) && element.includes(`form_element`))
            self.$refs[element].clean()
        })
      }
    },
    created() {
      I18n.locale = gon.language
      Vue.http.options.root = '/base/'+ gon.current_app +'/'
      for (let attrname in gon) {
        Vue.prototype['$' + attrname] = gon[attrname]
      }
      Vue.prototype.$global_utils = {print_element:print_element}
      this.$store.commit("set_subscription_id", gon.subscription_id)
    }
  })

  let headerObserver = new ResizeObserver(calculateHeaders)
  if ($('#headers').length > 0) {
    headerObserver.observe($('#headers')[0])
  }
})

window.take = function() {
  $('#download_image_body_form').empty();
  // CODIGO PARA VISULAIZAR LAS GRÁFICAS
  var svgElements = document.body.querySelectorAll('svg');
  svgElements.forEach(function(item) {
    item.setAttribute("width", item.getBoundingClientRect().width);
    item.setAttribute("height", item.getBoundingClientRect().height);
    item.style.width = null;
    item.style.height= null;
  });
  $(".deciding_container").css( "overflow-y", "visible" )
  // FIN CODIGO PARA VISULAIZAR LAS GRÁFICAS

  var class_to_print = "deciding_container"

  var element_to_print = document.querySelectorAll(".tab-pane-analytics.active > .assumption_summary_panel > .deciding_tab_content.active > .deciding_content > .deciding_container")[0];

  var h_total = element_to_print.scrollHeight
  var h =  Math.min(document.body.scrollHeight, element_to_print.offsetHeight)
  var max_iter = Math.ceil(h_total / h)
  var summary_tab = $(".assumption_summary").find(".assumption_summary_tab.active").html().replaceAll("\n", " ").replace(/<span.*<\/span>/g, "").trim();
  var deciding_element = $('.tab-pane-analytics.active').find(".active.deciding_panel_action")
  deciding_element = deciding_element.length ?  ("-"+deciding_element.html().trim()) : ""
  var file_name = (summary_tab+ deciding_element).toLowerCase().replaceAll(" ", "_");
  $("#file_name").val(file_name)

  for (var i= 0; i < max_iter ; i++){
  html2canvas(element_to_print, {scrollY:-1*i*h}).then(function(canvas) {
      var i_actual = $('input[id ^= img_val_]').length
      var canvas_value = canvas.toDataURL("image/png").split(',')[1];
      var new_input = '<input id="img_val_' + i_actual + '"name="img_val[' + i_actual + ']" type="hidden" value="'+ canvas_value+ '"></input>'
      $('#download_image_body_form').append(new_input)

      if (i_actual + 1 == max_iter){
        $('#download_image_form').submit()
      }
    });
  }
  $(".deciding_container").css( "overflow-y", "auto" )
}

let calculateHeaders = () => {
  $('.main_body').css('padding-top', `${$('#headers').height()}px`)
}
