import ApiClient from 'common/ApiClient'

const state = {
  isLoading: false,
  errors: [],
  isSubscribed: false
}

const mutations = {
  start_loading(state) { state.isLoading = true },
  end_loading(state) { state.isLoading = false },
  add_error(state, data) { state.errors.push(data) },
  clear_errors(state) { state.errors = [] },
  set_subscription(state, data) { state.isSubscribed = data}
}

const actions = {
  async setNoveltySubscription(context, newState) {
    const apiClient = new ApiClient()
    let url = `/base/${gon.current_app}/user_subscriptions/novelties/update`

    context.commit('start_loading')
    let response = await apiClient.put(url, newState)
    
    context.commit('end_loading')
    
    if(response.failed){
      context.commit('add_error', response.error)
      return state.isSubscribed
    }

    context.commit('set_subscription', response.data)
    return state.isSubscribed
  },
}

export default {
  namespaced: true,
  name: "user_subscription",
  state,
  mutations,
  actions
}