import ApiClient from 'common/ApiClient'
import mixins from 'common/mixins.js'

const searchUrl = (searchType, tokenId) => {
  return `${gon.url_for.searches.replace(
    ':search_type',
    searchType
  )}/${tokenId}`
}

const state = {
  tokenId: '',
  searchTokens: [],
  queryFormat: 'substring',
  queryField: 'all',
  searchType: '',
  groupType: '',
  filters: [],
  documents: [],
  numFound: 0,
  facets: [],
  searching: false,
  searchGroups: [],
  sort: 'score desc',
  pending: 0,
  groupResults: {},
  errors: [],
  rows: 25,
  page: 0,
  specialFilter: false,
  searchTokensFacets: [],
  savedOption: '',
  breadcrumbsForSelected: [],
  selectedLetter: '',
  inputSearchText: '',
  binCard: {}
}

const getters = {
  searchParams(state) {
    return {
      search_tokens: state.searchTokens,
      query_format: state.queryFormat,
      query_field: state.queryField,
      filters: state.filters,
      last_saved_option: state.savedOption,
      last_selected_letter: state.selectedLetter,
      last_search_text: state.inputSearchText,
      rows: state.rows,
      page: state.page,
      sort: state.sort
    }
  },
  appliedFilters(state) {
    return state.filters.length > 0
  }
}

const mutations = {
  setTokenId(state, data) { state.tokenId = data },
  setSearchTokens(state, data) { state.searchTokens = data },
  setQueryFormat(state, data) { state.queryFormat = data },
  setQueryField(state, data) { state.queryField = data },
  setSearchType(state, data) { state.searchType = data },
  setGroupType(state, data) { state.groupType = data },
  setSearchGroups(state, data) { state.searchGroups = data },
  setFilters(state, data) { state.filters = data },
  setSearching(state, data) { state.searching = data },
  increasePending(state) { state.pending++ },
  decreasePending(state) { state.pending-- },
  setRows(state, data) { state.rows = data },
  increasePage(state) { state.page++ },
  resetPages(state) { state.page = 0 },
  addError(state, data) { state.errors.push(data) },
  clearErrors(state) { state.errors = [] },
  setGroupResult(state, data) {
    state.groupResults = { ...state.groupResults, [data.name]: data.result }
  },
  resetGroupResults(state) {
    state.groupResults = {}
  },
  resetFilters(state) {
    state.filters = []
  },
  setResults(state, data) {
    state.documents = data.result
    state.numFound = data.total
    state.facets = data.facets
  },
  changeSpecialFilter(state) { state.specialFilter = !state.specialFilter },
  setSearchTokensFacets(state, data) {state.searchTokensFacets = data},
  setSavedOption(state, data) {state.savedOption = data},
  setBreadcrumbs(state, data) {state.breadcrumbsForSelected = data},
  setInputSearchText(state, data) {state.inputSearchText = data},
  setLastSelectedLetter(state, data) {state.selectedLetter = data},
  setBINCard(state, data) {state.binCard = data}
}

const actions = {
  async performSearch(context) {
    context.commit("clearErrors")

    if (context.state.searchType == 'general')
      context.dispatch("performGeneral")
    else
      context.dispatch("performAdvanced")
  },

  async performGeneral(context) {
    context.commit("setSearching", true)

    if (context.state.tokenId)
      context.dispatch("updateGeneraltoken")

    context.state.searchGroups.forEach((search) => {
      context.dispatch("performSearchGroup", search)
    })
  },

  async updateGeneraltoken(context) {
    const api = new ApiClient()
    let url = searchUrl(context.state.searchType, context.state.tokenId)
    await api.put(url, context.getters.searchParams)
  },

  async performAdvanced(context) {
    context.commit("setSearching", true)

    const api = new ApiClient()
    let url = searchUrl(context.state.searchType, context.state.tokenId)
    let response = await api.put(url, {
      ...context.getters.searchParams,
      ...{
        search_type: context.state.searchType,
        group_type: context.state.groupType
      }
    })

    if (response.ok) {
      context.commit('setResults', response.data)
    } else {
      context.commit('addError', 'advanced_search_failed')
    }
    context.commit("setSearching", false)
  },

  async performSearchGroup(context, searchAttributes) {
    context.commit("increasePending")

    let token = (context.state.groupResults[searchAttributes.name] || {}).token
    if (!token)
      token = await context.dispatch("createToken", searchAttributes)

    context.commit('setGroupResult', {
      name: searchAttributes.name,
      result: { result: [], total: 0, token: token, loading: true }
    })

    const api = new ApiClient()
    let url = searchUrl(searchAttributes.search_type, token.id)
    let response = await api.put(url, { ...context.getters.searchParams, empty_facets: 'true' })

    if (response.ok) {
      context.commit('setGroupResult', {
        name: searchAttributes.name,
        result: { ...response.data, token: token, loading: false }
      })
    } else {
      context.commit('addError', searchAttributes.name + '_search_failed')
    }

    context.commit("decreasePending")
    if (context.state.pending == 0)
      context.commit("setSearching", false)
  },

  async createToken(context, searchAttributes) {
    const api = new ApiClient()
    let url = `${gon.url_for.create_token_searches.replace(':search_type', searchAttributes.search_type)}`
    let response = await api.post(url, { ...context.getters.searchParams, ...searchAttributes })
    return response.data
  },

  async fetchBinCard(context, searchToken) {
    const api = new ApiClient()
    const searhTokenUrlParam = encodeURIComponent(searchToken)
    let url = `${gon.url_for.bin_card_path.replace(':id', searhTokenUrlParam)}`
    context.commit("setBINCard", {})
    let response = await api.get(url)
    if (response.ok) {
      context.commit("setBINCard", response.data)
      return response.data
    } else {
      return {}
    }
  }
}

export default {
  namespaced: true,
  name: "search",
  state,
  getters,
  mutations,
  actions
}
